<template>
  <a-modal v-model="modalVisible" title="通话记录" :width="1250" :footer="null" :centered="true" @cancel="cancelModal" :bodyStyle="bodyStyle">
    <a-table :columns="tableColumns" :data-source="tableData" row-key="calllogid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
      <span slot="begin_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      <span slot="end_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
      <span slot="duration" slot-scope="value">
            <a-tooltip :title="value ? moment(value).format('秒') : ''">
              <span>{{value ? moment(value).format('秒') : ''}}</span>
            </a-tooltip>
          </span>
      <span slot="play" slot-scope="value, record">
            <a-button type="link" size="small" @click="play(value, record)">播放录音</a-button>
          </span>
      <span slot="download" slot-scope="value, record">
            <a-button type="link" size="small" @click="download(value, record)">下载</a-button>
          </span>
    </a-table>
    <div id="myframe" class="page-wrapper" style="text-align: center;margin-top: 10px;margin-bottom: -10px" v-show="show">
<!--      <iframe id="content"-->
<!--              :src="tapeUrl" ref="iframe_a"-->
<!--              width="1205"-->
<!--              height="185"-->
<!--              frameborder="no"-->
<!--              border="0"-->
<!--              marginwidth="0"-->
<!--              marginheight="0"-->
<!--              scrolling="no"-->
<!--              allowtransparency="yes">-->
<!--      </iframe>-->
      <audio
          :src="tapeUrl"
          autoplay="autoplay"
          controls="controls"
          ref="audio"
      >Your browser does not support the audio element.</audio>
    </div>

    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
  </a-modal>
</template>


<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {getCalllog} from "A/thjlcx";

export default{
  name: 'wlwCheckTraceBack',
  mixins: [pagination],
  props: {
    Datadetail:{
      default: null
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      show:false,
      tapeUrl:'',
      ipnetnum:'',
      eventstarttime:'',
      eventendtime:'',
      moment,
      modalVisible:false,
      bodyStyle:{
      },
      queryParams: {
      },
      tableColumns:[
        {
          title: '主叫号码',
          dataIndex: 'caller',
          key: 'caller',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '被叫号码',
          dataIndex: 'called',
          key: 'called',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '通话开始时间',
          dataIndex: 'begin_time',
          key: 'begin_time',
          ellipsis: true,
          scopedSlots: { customRender: 'begin_time' }
        },
        {
          title: '通话结束时间',
          dataIndex: 'end_time',
          key: 'end_time',
          ellipsis: true,
          scopedSlots: { customRender: 'end_time' }
        },
        {
          title: '通话时间',
          dataIndex: 'duration',
          key: 'duration',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '通话类型',
          dataIndex: 'call_type',
          key: 'call_type',
          align: 'center',
          ellipsis: true,
        },{
          title:'录音播放',
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'play' }
        }, {
          title:'录音下载',
          align: 'center',
          width: 150,
          scopedSlots: { customRender: 'download' }
        }
      ],
      tableLoading: false,
      tableData:[]
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.show=false
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    cancelModal(e) {
      this.modalVisible = false
    },
    init() {
      this.getTableData();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let result=(moment(this.Datadetail.eventstarttime, 'YYYYMMDDHHmmss').valueOf())/1000+90;
      this.Datadetail.eventendtime=moment(result * 1000).format('YYYYMMDDHHmmss');
      let params = {
        ...this.queryParams,
        caller:this.Datadetail.ipnetnum,
        begintime: this.Datadetail.eventstarttime,
        endtime: this.Datadetail.eventendtime,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getCalllog(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          //this.pagination.total = res.count;
          this.tableData.map(function (val) {
            if (val.call_type == "IN") {
              val.call_type = '呼入'
            } else if (val.call_type == "OUT") {
              val.call_type = '呼出'
            } else if (val.call_type == "LO") {
              val.call_type = '内部通话'
            }
            val.duration =val.duration+'秒'
          })
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    play(value, record) {
      // this.$confirm({
        // title: '',
        // content: '确定要播放该录音吗?',
        // okText: '确定',
        // cancelText: '取消',
        // onOk: () => {
          this.playRecords(record);
          this.show=true;
      //   },
      //   onCancel() {
      //   },
      // });
    },
    playRecords(record) {
      // this.showLoading();
      // if (calllogid) {
      //   let params = {
      //     calllogid
      //   };
      //   getCalllog(params).then(res => {
      //     this.hideLoading();
      //     if (res && res.returncode == '0') {
      //       this.$message.success('操作成功');
      //       var playtape = res.item[0].playtape
      //       console.log(playtape)
      //       //window.open(playtape)
      //       this.tapeUrl=playtape
      //       console.log(this.tapeUrl)
      //     } else {
      //       this.$message.error(res.errormsg || '操作失败');
      //     }
      //   })
      // }
      let saveUrl=record.saveurl
      let caller = record.caller
      let time = moment(record.begin_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD-HH-mm-ss")
      this.tapeUrl=saveUrl+"/"+caller+"-"+time+".mp3"
    },
    download(value,record){
      // this.$confirm({
        // title: '',
        // content: '确定要下载该录音文件吗?',
        // okText: '确定',
        // cancelText: '取消',
        // onOk:() => {
        //   this.getRecords(record.calllogid);
        // },
        // onCancel() {},
      // });
      let saveUrl=record.saveurl
      let caller = record.caller
      let time = moment(record.begin_time,'YYYYMMDDHHmmss').format("YYYY-MM-DD-HH-mm-ss")
      this.tapeUrl=saveUrl+"/"+caller+"-"+time+".mp3"

      const link = document.createElement('a');
      // 这里是将链接地址url转成blob地址，
      fetch(this.tapeUrl).then(res => res.blob()).then(blob => {
        link.href = URL.createObjectURL(blob)
        // 下载文件的名称及文件类型后缀
        link.download = this.tapeUrl;
        document.body.appendChild(link)
        link.click()
        //在资源下载完成后 清除 占用的缓存资源
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      });
      // window.open(this.tapeUrl)
    },
    getRecords(calllogid){
      this.showLoading();
      if(calllogid) {
        let params = {
          calllogid
        };
        getCalllog(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            var gettape=res.item[0].gettape
            window.open(gettape)
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>

