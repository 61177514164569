<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="场景类型" prop="scenetypeid" style="width: 25%">
              <a-select v-model="queryParams.scenetypeid">
                <a-select-option v-for="(item, index) in sceneTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
<!--            <a-form-model-item label="系统名称" prop="systemnameid" style="width: 25%">-->
<!--              <a-select v-model="queryParams.systemnameid">-->
<!--                <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="事件状态" prop="eventstatus" style="width: 25%">
                <a-select v-model="queryParams.eventstatus">
                  <a-select-option :value="1">未处理</a-select-option>
                  <a-select-option :value="2">已处理</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="deleteBatch()">删除</a-button>-->
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="duration" slot-scope="value">
            <span>{{value?value+'分钟':''}}</span>
          </span>
          <span slot="check" slot-scope="value, record">
            <a-button type="link" size="small" @click="checkCalllog(value,record)">播放</a-button>
          </span>
          <span slot="image" slot-scope="value, record">
            <a-button v-if="record.devicenum&&record.showimage==='1'" type="link" size="small" @click="lookImage(value,record)">图片</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'detail-'+record.eventrecordid">详情</a-menu-item>-->
                <a-menu-item v-if="deleteenable==1" :key="'delete-'+record.eventrecordid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="exportModalVisible" title="导出" centered>
      <template slot="footer">
        <a-button @click="exportModalVisible=false">取消</a-button>
        <a-button type="primary" @click="confirm">确认</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:12}">
        <a-form-model-item label="公司/项目" prop="monitorpointnum">
          <a-select v-model="formDatas.monitorpointnum" placeholder="请选择">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="导出时段" prop="queryInstallDate">
          <a-range-picker v-model="queryInstallDate"></a-range-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <imageBack :visible.sync="imageBackVisible" ref="imageBack" :EventData="eventData"></imageBack>
    <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import {deleteEventrecord, getEventrecordListByCondition} from "A/wlw";
import {
  eventstatus,
  eventstatusColors,
  finishSigns,
} from "@/json/wlwhistory";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {exportEventRecordExcel} from "A/jcgn";
import deviceselect from "@/mixins/deviceselect";
import imageBack from "V/jcxx/monitorfile/timeMonitoring/imageBack";
import checkTraceBack from "V/abnormalHistory/wlwbj/main/checkTraceBack";
export default {
  name: "eventHistory",
  mixins: [deptselect,pagination,deviceselect],
  components: {
    imageBack,
    checkTraceBack,
  },
  data() {
    return {
      moment,
      userdepidCascaderSelected: [],
      eventInfos:[
        {
          dickey:"9040101",
          dicvalue:"语音通话"
        }
      ],
      sceneTypes:[],
      systemNames:[],
      finishSigns,
      eventstatus,
      eventstatusColors,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      eventOptions:[],
      detailParams:{},
      queryParams:{
        monitorpointname:'',
        eventnum:'',
        eventcode:'',
        starttime:'',
        endtime:'',
        eventtype:904,
        eventstatus:'',
        plannedwork:'',
        querytype:5,
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
        },
        {
          title: '通话时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          scopedSlots: { customRender: 'duration' },
          width: 100,
        },
        {
          title: '开始时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 160,
        },
        {
          title: '结束时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventendtime' },
          width: 160,
        },
        {
          title: '完成标志',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'eventstatus' },
          width: 70
        },
        {
          title: '录音',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'check' }
        },
        {
          title: '图片',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          width: 50,
          scopedSlots: { customRender: 'operation' },
        }
      ],
      deleteenable:'',
      managetype:'',
      usertype:'',
      exportModalVisible:false,
      yearOptions: [],
      formDatas:{
        monitorpointnum:'',
        starttime:'',
        endtime:'',
        eventtype:904,
        querytype:5,
      },
      formRules: {
        monitorpointnum: [{required: true, message: '请选择公司/项目'}],
      },
      monitorpointList:[],
      checkTraceVisible:false,
      detaildata:'',
      imageBackVisible:false,
      eventData:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "通话历史"
    },
    breadcrumb() {
      const pages = [{name:"异常历史",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.formDatas.starttime = start;
      this.formDatas.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.deleteenable = logininfo.deleteenable;
      this.managetype=logininfo.managetype;
      this.usertype=logininfo.usertype;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.eventInfos=dictionary.eventInfos
      this.systemNames = dictionary.systemNames
      this.sceneTypes = dictionary.sceneTypes
    }
    this.getTableData()
    this.initDeptOptionsAll();
    this.getYearOptions();
    this.getMonitorPointNameList();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        dispatcher:this.userInfo.rolename==='项目工程值班员'?1:0,
        rolename:this.userInfo.rolename,
        history:true,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.eventcode='';
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.queryParams= {
        monitorpointname:'',
        eventnum:'',
        eventcode:'',
        starttime:'',
        endtime:'',
        eventtype:904,
        querytype:5,
        eventstatus:'',
      }
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let eventrecordid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'eventrecordid', eventrecordid);
      if(type == 'delete') {
        this.deleteConfirm(eventrecordid, record)
      }else if(type == 'detail'){
      }
    },
    checkCalllog(value,record){
      this.$refs.checkTrace.queryParams.caller = record.ipnetnum
      this.detaildata=record;
      this.checkTraceVisible = true
    },
    lookImage(value,record){
      this.eventData=record;
      this.imageBackVisible=true;
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventrecordid);
      }).catch(()=>{
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEventRecord()
      }).catch(()=>{
      });
    },
    exportEventRecord(){
      let params = {
        ...this.queryParams,
        history:true,
      }
      this.showLoading();
      exportEventRecordExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    confirm(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          let params = {...this.formDatas}
          this.showLoading();
          exportEventRecordExcel(params).then((res)=> {
            this.hideLoading();
            this.exportModalVisible = false
            this.$message.success("导出成功")
          }).catch(()=>{
            this.hideLoading();
          })
        }
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      for(let i=4; i>-1; i--) {
        this.yearOptions.push(currentYear-i+'');
      }
    },
  },
}
</script>
<style scoped>

</style>